<template>
  <page-container>
    <!-- <page-header title="Interviews & Music Videos" /> -->
    <v-expansion-panels v-model="panel">
      <v-expansion-panel v-for="item in content" :key="item.title">
        <v-expansion-panel-header color="grey darken-3"><span class="text-h5">{{
          item.title
        }}</span></v-expansion-panel-header>
        <v-expansion-panel-content color="grey darken-4 overflow-auto hide-scrollbar" style="max-height: 40vh">
          <div class="d-flex justify-space-around flex-wrap">
            <template v-for="item in item.links">
              <div :key="item.link" class="mt-3 mx-1 grey--text" style="width: 240px; ">
                <div class="py-1 px-2" style="background: black; font-size: .8rem;
                text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                  {{ item.title }}</div>
                <!-- <iframe :width="240" :height="140" :src="`https://www.youtube.com/embed/${item.link}`"
                  allow="fullscreen;" style="border: none">
                </iframe> -->
                <figure
                  style="position: relative; cursor: pointer;"
                  @click="playVideo(item)"
                >
                  <v-img
                    :width="240"
                    :height="140"
                    :src="`//img.youtube.com/vi/${item.link}/mqdefault.jpg`"
                  ></v-img>
                  <div class="centered">
                    <v-btn fab dark small color="red" class="elevation-2">
                      <v-icon>mdi-play</v-icon>
                    </v-btn>
                  </div>
                </figure>
              </div>
            </template>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog
      v-model="showDialog"
      :width="$vuetify.breakpoint.mdAndUp? 840 : 420"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark>
          <span>{{ currentSong.title }}</span>
          <span v-if="currentSong.channel" class="subtitle-2 grey--text pl-4"
            >℗ {{ currentSong.channel }}</span
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="ma-0 pa-0 text-center">
          <iframe
            :width="$vuetify.breakpoint.mdAndUp? 800 : 400"
            :height="$vuetify.breakpoint.mdAndUp? 500 : 250"
            :src="`https://www.youtube.com/embed/${currentSong.link}?autoplay=1`"
            allow="fullscreen;"
            style="border: none"
          >
          </iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </page-container>
</template>

<script>
/*
http://img.youtube.com/vi/<youtube-video-id>/default.jpg
http://img.youtube.com/vi/<youtube-video-id>/hqdefault.jpg
http://img.youtube.com/vi/<youtube-video-id>/mqdefault.jpg
http://img.youtube.com/vi/<youtube-video-id>/maxresdefault.jpg
*/
export default {
  data: () => ({
    panel: 0,
    showDialog: false,
    currentSong: {},
    content: [
      {
        title: 'Music Videos',
        links: [
          { link: 'KxY9mXfrXoY', title: 'Megh Boleche (Rabindrasangeet)', channel: 'GG Official' },
          { link: 'wrVMWd9ITKo', title: 'Madhobilata', channel: 'Melody Unlimited' },
          { link: 'QQqToVkTRzM', title: 'Koto Megh' },
          { link: 'RWKp_BtLCX4', title: 'Ure Jaa Bhese Jaa', channel: 'Cozmik Harmony' },
          { link: 'MUJ55RhlNy8', title: 'Dujoner Hriday Theke', channel: 'Cozmik Harmony' },
          { link: 'lDxQrlPMH3I', title: 'Kusum Kusum', channel: 'Cozmik Harmony' },
          { link: 'CV5kKzQjDT0', title: 'Neel Chhata', channel: 'Cozmik Harmony' },
          { link: '986VR0ys_18', title: 'Chilte Rod', channel: 'Cozmik Harmony' },
          { link: 'UuAcI6EF74c', title: 'Tumi Nei', channel: 'Cozmik Harmony' },
          { link: '2rXfQV09zCU', title: 'Srabon Ghera', channel: 'Cozmik Harmony' },
          { link: 'gL5oDb8wwTI', title: 'Raateri Taray Taray' }
        ]
      },
      {
        title: 'Interviews',
        links: [
          { link: '7V6n628awuU', title: 'Sakal Sakal - DD Bangla' },
          { link: 'gNNnAzHL61Y', title: 'Good Morning Akash' },
          { link: '-jXsVlV4b5g', title: 'Obelay Adda - One TV' },
          { link: 'HuiUBscHB0E', title: 'Chorcha@Chilekotha [Part-I] - Cozmik Harmony' },
          { link: 'UOCiAfEEgeo', title: 'Chorcha@Chilekotha [Part-II] - Cozmik Harmony' },
          { link: 'KDc1K9Varag', title: 'A Tribute to Pintu Bhattacharya - Akash 8' },
          { link: 'r-zSeq7_suE', title: 'Charan Chhuye Jai - One TV' },
          { link: '8Gj9pSr9rnc', title: 'Gaan Ontohin - DD Bangla' },
          { link: 'V1M_dsNJcCc', title: 'Aamar Maa - One TV' },
          { link: 'dJCnxxV29ng', title: 'Random Interview in Australia' },
          { link: 'zU3AiOF0t2g', title: 'Telephonic Interview [Part-I] - Srijoner Itikotha' },
          { link: 'y97UFLK0WCQ', title: 'Telephonic Interview [Part-II] - Srijoner Itikotha' }
        ]
      },
      {
        title: 'Live Concerts',
        links: [
          { link: 'ykrKVCrZoM8', title: 'With Srikanta Acharyya [Part-I]' },
          { link: 'z9uYw-gwtJY', title: 'With Srikanta Acharyya [Part-II]' },
          { link: 'A9v3WxS9LEY', title: 'With Srikanta Acharyya [Part-III]' },
          { link: 'QhWLsM1yDd0', title: 'Tomaro Janala Ki' },
          { link: 'PVrhLruEiUg', title: 'Asansol Bangla Sangeet Mela 2022' },
          { link: 'bhzdl-1wHzY', title: 'In the memory of Hemanta Mukherjee' },
          { link: 'Nt2xWSQWmrY', title: 'With Amrita Chatterjee' }
        ]
      }
    ]
  }),
  computed: {},
  methods: {
    playVideo (song) {
      this.currentSong = song
      this.showDialog = true
      // window.open('//youtube.com/watch?v=${item.link}', '_blank')
    },
    closeDialog () {
      this.showDialog = false
      setTimeout(() => {
        this.currentSong = {}
      }, 250)
    }
  },
  components: {
    PageContainer: () => import('@/components/reusable/PageContainer')
    // PageHeader: () => import('@/components/reusable/PageHeader')
  }
}
</script>

<style lang="scss" scoped>
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* button {
    box-shadow: 0 0 8px 2px #0f0f0f;
  } */
}
</style>
